import $ from 'jquery'

$(document).on('ready turbolinks:load', function () {
  correctFormatNumberFields();
});

$(document).on('shown.bs.modal', function () {
  correctFormatNumberFields();
});

export function numberWithCommas(x) {
  if (x == undefined || x == "") {
    return "";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

$(function () {
  $(document).on('input', '.js-format-int-field', function (e) {
    if (/\D/g.test(this.value)) {
      this.value = this.value.replace(/\D/g, '');
    }
    if ($(this).val().length > 10) {
      $(this).val($(this).val().slice(0, -1))
    }

    $(this).val(numberWithCommas($(this).val()));
  });

  $(document).on('input', '.js-format-float-field', function (evt) {
    var self = $(this);

    self.val(self.val().replace(/(?!^-)[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
    if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }

    var number_after_dot = self.val().toString().split('.')[1];
    if (number_after_dot && number_after_dot.toString().length > 1) {
      var current_value = self.val().toString().slice(0, -1);
      self.val(parseFloat(current_value));
    }

    $(this).val(numberWithCommas($(this).val()));
  });
});

function correctFormatNumberFields() {
  var $js_display_formatted_number = $('.js-display-formatted-number');
  var $js_display_input_formatted_number = $('input.js-display-input-formatted-number');

  for (let i = 0; i < $js_display_formatted_number.length; i++) {
    var current_value = $($js_display_formatted_number[i]).text();
    var formatted_value = numberWithCommas(current_value);
    var parts = formatted_value.toString().split(".");

    if (parts[1] == "0") {
      $($js_display_formatted_number[i]).text(parts[0]);
    } else {
      $($js_display_formatted_number[i]).text(formatted_value);
    }
  }

  for (let i = 0; i < $js_display_input_formatted_number.length; i++) {
    var current_value = $($js_display_input_formatted_number[i]).val();
    var formatted_value = numberWithCommas(current_value);
    var parts = formatted_value.toString().split(".");

    if (parts[1] == "0") {
      $($js_display_input_formatted_number[i]).val(parts[0]);
    } else {
      $($js_display_input_formatted_number[i]).val(formatted_value);
    }
  }
}
