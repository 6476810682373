// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
import $ from "jquery";

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

setTimeout(function () {
  $('.js-flash-msg-area').fadeOut('fast');
}, 3000);

setInterval(function () {
  let current_location = location.href;
  let current_pathname = location.pathname;
  let query_string = location.search;
  let url = '/manufacturing/products';

  if (current_pathname == '/manufacturing/products') {
    if (query_string.length > 0) {
      url = url + query_string;
    }

    $.ajax({
      url: url,
      method: 'GET',
      contentType: "application/json",
      dataType: "json",
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (data) {
      for (let i = 0; i < data.length; i++) {
        let updated_achievement_amount = data[i].achievement_amount + data[i].unit_type;
        let updated_stock_amount = data[i].amount_of_stock + data[i].unit_type;

        $('.item-row-' + data[i].id).find('.js-achieve-col-' + data[i].id).html(updated_achievement_amount);
        $('.item-row-' + data[i].id).find('.js-product-stock-col-' + data[i].id).html(updated_stock_amount);
      }
    });
  }

  if (current_pathname == '/stock/products') {
    url = '/stock/products';
    let current_date = $("input[name='day']").val();

    if (query_string.length > 0) {
      url = url + query_string;
    }

    $.ajax({
      url: url,
      method: 'GET',
      contentType: "application/json",
      dataType: "json",
      data: { 'authenticity_token': $('meta[name="csrf-token"]').attr('content'), day: current_date }
    }).done(function (data) {
      for (let i = 0; i < data.length; i++) {
        let updated_stock_amount = data[i].amount_of_stock + data[i].unit_type_localization;
        let updated_stock_amount_bring = data[i].amount_bring_of_stock + data[i].unit_type_localization;
        let updated_total_requested_amount = data[i].total_requested_amount + data[i].unit_type_localization;
        let updated_total_items_not_delivered = data[i].total_items_not_delivered + data[i].unit_type_localization;
        let updated_total_ordered_today = data[i].total_ordered_amount_date + data[i].unit_type_localization;

        $('.item-row-' + data[i].id).find('.js-code-col-' + data[i].id).html(data[i].code);
        $('.item-row-' + data[i].id).find('.js-dough-name-col-' + data[i].id).html(data[i].dough_name);
        $('.item-row-' + data[i].id).find('.js-stock-amount-col-' + data[i].id).html(updated_stock_amount);
        $('.item-row-' + data[i].id).find('.js-stock-amount-bring-col-' + data[i].id).html(updated_stock_amount_bring);
        $('.item-row-' + data[i].id).find('.js-total-requested-amount-col-' + data[i].id).html(updated_total_requested_amount);
        $('.item-row-' + data[i].id).find('.js-total-items-not-delivered-col-' + data[i].id).html(updated_total_items_not_delivered);
        $('.item-row-' + data[i].id).find('.js-total-ordered-amount-today-col-' + data[i].id).html(updated_total_ordered_today);
      }
    });
  }

  if (current_pathname == '/stock/materials') {
    url = '/stock/materials';

    $.ajax({
      url: url,
      method: 'GET',
      contentType: "application/json",
      dataType: "json",
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (data) {
      for (let i = 0; i < data.length; i++) {
        let updated_display_stock_volume_with_unit = data[i].display_stock_volume_with_unit;

        $('.item-row-' + data[i].id).find('.js-stock-volume-col-' + data[i].id).html(updated_display_stock_volume_with_unit);
      }
    });
  }

  if (current_pathname == '/manufacturing/achievement_produces') {
    url = '/manufacturing/achievement_produces';

    $.ajax({
      url: url,
      method: 'GET',
      contentType: "application/json",
      dataType: "json",
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function (data) {
      for (let i = 0; i < data.length; i++) {
        let updated_amount_of_stock = data[i].amount_of_stock;

        $('.item-row-' + data[i].id).find('.js-product-stock-col-' + data[i].id).html(updated_amount_of_stock);
      }
    });
  }
}, 5000);

$(document).on('turbolinks:load', function () {
  initDateRangePicker();

  let screen_width = $(window).width();
  if (screen_width < 992) {
    swapWorkerFormBoxs();
    swapWorkerFormFields();
  }

  initSidebarMenuState();
  handleClientPaymentRaditoChangeState();
  handleClientBillTargetTypeRaditoChangeState();
  // preventEnterSubmit();
  // preventEnterSubmitOnModal();
  autosubmit_init($('.autosubmit'));
});

$(window).on('resize', function () {
  var win = $(this); //this = window
  if (win.width() < 992) {
    swapWorkerFormBoxs();
    swapWorkerFormFields();
  }
});

// function preventEnterSubmit() {
//   $('form:not(.allow-enter-submit)').on('keypress', e => {
//     if (e.keyCode == 13) {
//       return false;
//     }
//   });
// }
//
// function preventEnterSubmitOnModal() {
//   $(document).on('shown.bs.modal', function() {
//     $('form').on('keypress', e => {
//       if (e.keyCode == 13) {
//         return false;
//       }
//     });
//   });
// }

$(document).on('shown.bs.modal', function () {
  $('.js-source-products-ajax').select2({
    ajax: {
      url: '/products.json',
      dataType: 'json',
      data: function (params) {
        return {
          q: {name_or_code_cont: params.term},
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let item_price = item.price;
            let item_unit_type = item.unit_type_localization;
            let client_id = $('select.js-source-clients').val();

            if (client_id != null) {
              for (let i = 0; i < item.client_products.length; i++) {
                if (parseInt(client_id) == item.client_products[i].client_id) {
                  item_name = item.client_products[i].special_product_name;
                  item_price = item.client_products[i].price;
                  item_unit_type = item.client_products[i].unit_type_localization;

                  break;
                }
              }
            }

            return {text: item_name, id: item.id, price: item_price, unit_type: item_unit_type}
          })
        };
      },
      cache: true,
      minimumInputLength: 1
    },
    dropdownParent: $(".modal"),
    placeholder: "商品名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });

  $('.js-source-products-ajax').on('select2:select', function (e) {
    var data = e.params.data;
    var parent = $(e.currentTarget).parents('.new_order_item, .new_produce');
    if ($(parent).find('input.js-order-item-amount').length > 0) {
      var item_amount = $(parent).find('input.js-order-item-amount').val().trim();
    }

    $(parent).find('#select2-order_item_product_id-container').html(data.text);
    $(parent).find('.js-product-unit-type').html(data.unit_type);
    $(parent).find('.js-product-price').html(data.price);

    if (item_amount != "") {
      var order_item_total_fee = data.price * parseFloat(item_amount);
      if (order_item_total_fee > 0) {
        order_item_total_fee = order_item_total_fee.toFixed(1);
      }
      $(parent).find('.js-order-total-fee').html(order_item_total_fee);
    }

    if (data.unit_type == 'kg') {
      $(parent).find('input.js-order-item-amount').removeClass('int-field').addClass('float-field');
    } else {
      $(parent).find('input.js-order-item-amount').removeClass('float-field').addClass('int-field');
    }
  });

  $('input.js-order-item-amount').keyup(function (event) {
    var parent = $(this).parents('.new_order_item');
    let product_price = $(parent).find('.js-product-price').html();
    let current_input = $(this).val().trim();

    if ($.isNumeric(current_input) && $.isNumeric(product_price)) {
      let item_amount_converted = parseFloat(current_input);
      let product_price_converted = parseFloat(product_price);
      var order_item_total_fee = item_amount_converted * product_price_converted;

      $(parent).find('.js-order-total-fee').html(order_item_total_fee);
    }
  });

  $('.js-source-clients').select2({
    dropdownParent: $(".modal"),
    placeholder: "得意先名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });

  $('.js-return-registration-modal').on('cocoon:after-insert', () => {
    $('.js-source-products').select2({
      dropdownParent: $(".modal"),
      placeholder: "商品名",
      language: {
        noResults: function () {
          return "見つかりません";
        }
      }
    });
  });

  $('.js-source-products').select2({
    dropdownParent: $(".modal"),
    placeholder: "商品名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });

  $('.js-source-shops').select2({
    ajax: {
      url: '/shops.json',
      dataType: 'json',
      data: function (params) {
        return {
          q: {client_id_eq: $('select.js-source-clients').val()},
          page: params.page || 1
        }
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
          results: $.map(data, function (item) {
            let item_name = item.name;
            let client_id = $('select.js-source-clients').val();

            return {text: item_name, id: item.id}
          })
        };
      },
      cache: true,
      minimumInputLength: 1
    },
    dropdownParent: $(".modal"),
    placeholder: "お店",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });

  $('.js-source-doughs-modal').select2({
    dropdownParent: $(".modal"),
    placeholder: "生地名",
    language: {
      noResults: function () {
        return "見つかりません";
      }
    }
  });
});

function handleClientPaymentRaditoChangeState() {
  let arr = ['1', '2'];

  for (let i = 0; i < arr.length; i++) {
    let item_dom = 'input[type="radio"][name="client[payment_date' + arr[i] + '_options]"]';

    $(item_dom).change(function () {
      let parent = $(this).parents('.js-radio-btns-area');
      let input_dom = 'input[name="client[payment_date' + arr[i] + ']"';
      let input_payment = $(parent).find(input_dom);

      if (this.value == ('input_date' + arr[i])) {
        $(input_payment).prop('disabled', false);
        if (arr[i] == '1') {
          $('input[name="client[payment_date2]"').prop('disabled', false);
          $('input[type="radio"][name="client[payment_date2_options]"]').prop("disabled", false);
        } else {
          $('input[name="client[payment_date1]"').prop('disabled', false);
          $('input[type="radio"][name="client[payment_date1_options]"]').prop("disabled", false);
        }
      } else if (this.value == ('end_day_month' + arr[i])) {
        $(input_payment).val('');
        $(input_payment).attr('value', '');
        $(input_payment).prop('disabled', true);

        if (arr[i] == '1') {
          $('input[name="client[payment_date2]"').val('');
          $('input[name="client[payment_date2]"').attr('value', '');
          $('input[name="client[payment_date2]"').prop('disabled', true);
          $('input[type="radio"][name="client[payment_date2_options]"]').prop('checked', false);
          $('input[type="radio"][name="client[payment_date2_options]"]').prop("disabled", true);
        }
      }
    });
  }
}

function handleClientBillTargetTypeRaditoChangeState() {
  let item_dom = 'input[type="radio"][name="client[bill_target_type]"]';

  $(item_dom).change(function () {
    if (this.value == 'bill_to_shop') {
      $('.js-is-show-client-name-on-bill-area').removeClass('d-none');
    } else if (this.value == 'bill_to_client') {
      $('.js-is-show-client-name-on-bill-area').addClass('d-none');
      $('.js-is-show-client-name-on-bill-area').find('input[type="checkbox"]').prop('checked', false);
    }
  });
}

$(document).on('click', 'input[type="radio"][name="product[tax_val_type]"]', function (e) {
  if ($(this).attr("checked") == "checked") {
    $(this).prop('checked', false);
    $('input[type="radio"][name="product[tax_val_type]"]').removeAttr('checked');
  } else {
    $(this).prop('checked', true);
    $('input[type="radio"][name="product[tax_val_type]"]').removeAttr('checked');
    $(this).attr('checked', 'checked');
  }
});

function initSidebarMenuState() {
  let current_location = location.href;
  let current_pathname = location.pathname;
  let elements = $('.sidebar').find('.s-item');

  for (let i = 0; i < elements.length; i++) {
    let dropdown_content_items = $(elements[i]).find('.sidebar-dropdown-container ul li');
    let $dropdown_btn = $(elements[i]).find('.sidebar-dropdown-btn');

    let s_item_root_link = $(elements[i]).find('a.s-item-a-root')[0];
    if (s_item_root_link != undefined) {
      let item_pathname = $(s_item_root_link).attr('href');

      if (current_pathname == item_pathname || current_pathname == '/orders/new') {
        openSidebarItemState($dropdown_btn);
        return false;
      }
    }

    for (let j = 0; j < dropdown_content_items.length; j++) {
      let item = dropdown_content_items[j];
      let item_href = $(item).find('a').attr('href').split("?")[0];

      if (current_location.indexOf(item_href) != -1) {
        openSidebarItemState($dropdown_btn, dropdown_content_items, item);
        return false;
      }
    }
  }
}

function swapWorkerFormBoxs() {
  let div1 = $('.form-workers-page .second-box-wrapper');
  let div2 = $('.form-workers-page .third-box-wrapper');

  swapPosition2Boxs(div1, div2);
}

function swapWorkerFormFields() {
  let secondbox_col6_1 = $($('.form-workers-page .second-box-wrapper .row .col-6')[1]);
  let secondbox_col6_2 = $($('.form-workers-page .second-box-wrapper .row .col-6')[2]);

  swapPosition2Boxs(secondbox_col6_1, secondbox_col6_2);

  let secondbox_col6_0 = $($('.form-workers-page .second-box-wrapper .row .col-6')[0]);
  let secondbox_col6_4 = $($('.form-workers-page .second-box-wrapper .row .col-6')[4]);

  secondbox_col6_0.insertBefore(secondbox_col6_4);
}

$(document).on('click', '.js-sp-menu-icon', function (e) {
  e.preventDefault();

  sp_sidebar_open();
});

$(document).on('click', '.overlay, .sp-sidebar-head-close', function (e) {
  e.preventDefault();

  sp_sidebar_close();
});

$(document).on('click', '.workers-page .sp-head-filter-icons .btn-search', function (e) {
  e.preventDefault();

  $filter_workers_area = $(this).parents('.workers-page').find('.filter-workers-area');

  if ($($filter_workers_area).hasClass('d-none')) {
    $($filter_workers_area).removeClass('d-none');
  } else {
    $($filter_workers_area).addClass('d-none');
  }
});

$(document).on('click', '.btn-reset', function (e) {
  var elements = $(this).parents('form .card').find('input');
  var select_elements = $(this).parents('form .card').find('select');

  for (var i = 0; i < elements.length; i++) {
    if (elements[i].type == "text" || elements[i].type == "email" || elements[i].type == "password" || elements[i].type == "number" || elements[i].type == "tel") {
      elements[i].value = "";
    }
  }

  for (var i = 0; i < select_elements.length; i++) {
    select_elements[i].value = "";
  }
});

$(document).on('click', '.js-cost-histories', function (e) {
  e.preventDefault();

  let product_id = $(this).data('item-id');
  let cost_type = "";

  if ($(this).hasClass('manufactoring-cost')) {
    cost_type = "manufacturing_cost";
  } else if ($(this).hasClass('material-cost')) {
    cost_type = "material_cost";
  }

  if (product_id != "" && cost_type != "") {
    $.ajax({
      url: '/products/find_cost_histories',
      method: 'POST',
      data: {id: product_id, cost_type: cost_type, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-order-producing', function (e) {
  e.preventDefault();

  let product_id = $(this).data('item-id');
  let shop_id = $(this).data('shop-id');

  if (product_id != "") {
    $.ajax({
      url: '/stock/products/' + product_id + '/new_order_producing',
      method: 'GET',
      data: { shop_id: shop_id, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-add-manufacturing-produce', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/manufacturing/products/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-add-manufacturing-dough', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/manufacturing/doughs/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-create-produce', function (e) {
  e.preventDefault();

  let produce_order_id = $(this).data('produce-order-id');

  $.ajax({
    url: '/manufacturing/produces/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content'), produce_order_id: produce_order_id}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-list-produces', function (e) {
  e.preventDefault();

  let produce_order_id = $(this).data('produce-order-id');

  $.ajax({
    url: '/manufacturing/produces',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content'), produce_order_id: produce_order_id}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-edit-stock-product', function (e) {
  e.preventDefault();

  let product_id = $(this).data('item-id');

  if (product_id != "") {
    $.ajax({
      url: '/stock/products/' + product_id + '/edit',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-edit-return', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');

  if (item_id != "") {
    $.ajax({
      url: '/returns/' + item_id + '/edit',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-destroy-return', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');

  if (item_id != "") {
    $.ajax({
      url: '/returns/' + item_id + '/confirm_delete',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-edit-stock', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');

  if (item_id != "") {
    $.ajax({
      url: '/stock/materials/' + item_id + '/edit_stock',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-edit-stock-material', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');

  if (item_id != "") {
    $.ajax({
      url: '/stock/materials/' + item_id + '/edit',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-update-produce', function (e) {
  e.preventDefault();

  let item_id = $(this).data('produce-id');
  let amount = $(this).parents('.row.item').find('input.js-produce-amount-field').val();

  if (item_id != "") {
    $.ajax({
      url: '/manufacturing/produces/' + item_id,
      method: 'PUT',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content'), produce: {amount: amount}}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-add-temporary-registration', function (e) {
  e.preventDefault();

  let deliver_order_id = $(this).data('deliver-order-id') || null;
  let deliver_id = $(this).data('deliver-id');

  if (deliver_order_id != '') {
    $.ajax({
      url: '/delivers/' + deliver_id + '/new_temporary_registration?deliver_order_id=' + deliver_order_id,
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-return-registration', function (e) {
  e.preventDefault();

  let deliver_id = $(this).data('deliver-id');

  if (deliver_id != '') {
    $.ajax({
      url: '/delivers/' + deliver_id + '/new_return_registration',
      method: 'GET',
      data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '.js-btn-update-produce-status', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');
  let btn_action = '';
  if ($(this).hasClass('btn-status-in_progress')) {
    btn_action = 'btn-status-inprogress';
  } else if ($(this).hasClass('btn-cancel-order')) {
    btn_action = 'btn-status-cancel';
  } else if ($(this).hasClass('btn-update-produce')) {
    btn_action = 'btn-update-produce';
  }

  if (item_id != "") {
    if ($(this).hasClass('btn-status-nothing')) {
      $.ajax({
        url: '/manufacturing/products/' + item_id,
        method: 'PUT',
        data: {produce: {status: 'in_progress'}, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
      }).done(function () {
      });
    } else if ($(this).hasClass('btn-status-in_progress') || $(this).hasClass('btn-cancel-order') || $(this).hasClass('btn-update-produce')) {
      $.ajax({
        url: '/manufacturing/products/' + item_id + '/edit',
        method: 'GET',
        data: {btn_action: btn_action, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
      }).done(function () {
      });
    }
  }
});

$(document).on('click', '.js-btn-update-dough', function (e) {
  e.preventDefault();
  let item_id = $(this).data('item-id');

  $.ajax({
    url: `/manufacturing/doughs/${item_id}/edit`,
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

function swapPosition2Boxs(div1, div2) {
  var tdiv1 = div1.clone();
  var tdiv2 = div2.clone();

  if (!div2.is(':empty')) {
    div1.replaceWith(tdiv2);
    div2.replaceWith(tdiv1);
  }
}

$(document).on('click', '.sidebar-dropdown-btn', function (e) {
  let elements = $(this).parents('.sidebar').find('.sidebar-dropdown-btn').not($(this));

  for (let i = 0; i < elements.length; i++) {
    if ($(elements[i]).hasClass('active')) {
      closeSidebarItemState(elements[i]);
    }
  }

  handleSidebarMenuState($(this));
});

function closeSidebarItemState($object) {
  $($object).removeClass('active');

  $($object).find('img').removeClass('filter-c-blue');
  $($object).find('span.float-end img').addClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-180');
  $($object).find('span.float-end img').addClass('rotated-90');

  let dropdownContent = $($object).siblings(".sidebar-dropdown-container")[0];

  $(dropdownContent).hide('slow');
}

function openSidebarItemState($object, dropdown_content_items = null, active_item = null) {
  $($object).addClass('active');

  $($object).find('img').addClass('filter-c-blue');
  $($object).find('span.float-end img').removeClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-90');
  $($object).find('span.float-end img').addClass('rotated-180');

  let dropdownContent = $($object).siblings(".sidebar-dropdown-container")[0];

  if (dropdown_content_items) {
    for (let j = 0; j < dropdown_content_items.length; j++) {
      $(dropdown_content_items[j]).removeClass('active');
    }

    $(active_item).addClass('active');
  }

  $(dropdownContent).show('slow');
}

function handleSidebarMenuState($this) {
  if ($($this).hasClass('active')) {
    $($this).removeClass('active');

    $($this).find('img').removeClass('filter-c-blue');
    $($this).find('span.float-end img').addClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-180');
    $($this).find('span.float-end img').addClass('rotated-90');
  } else {
    $($this).addClass('active');

    $($this).find('img').addClass('filter-c-blue');
    $($this).find('span.float-end img').removeClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-90');
    $($this).find('span.float-end img').addClass('rotated-180');
  }

  let dropdownContent = $($this).siblings(".sidebar-dropdown-container")[0];

  if ($(dropdownContent).css("display") === "block") {
    $(dropdownContent).hide('slow');
  } else {
    $(dropdownContent).show('slow');
  }
}

function initDateRangePicker() {
  let drop_option = 'down';

  if (location.pathname.indexOf('/clients/') != -1) {
    drop_option = 'auto';
  }

  $('input[name="daterange"]').daterangepicker({
    locale: ja_date_locale,
    autoUpdateInput: false,
    autoApply: true
  });

  $('input.singledate').daterangepicker({
    locale: ja_date_locale,
    singleDatePicker: true,
    autoUpdateInput: false,
    drops: drop_option,
    autoApply: true
  });

  $('input.js-deliver-singledate-filter').daterangepicker({
    locale: ja_date_locale,
    singleDatePicker: true,
    autoUpdateInput: false,
    drops: drop_option,
    autoApply: true
  });

  $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD') + ' ~ ' + picker.endDate.format('YYYY/MM/DD'));
  });

  $('input[name="daterange"]').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  $('input.singledate').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD'));

    if ($(this).hasClass('js-order-filter-date-field')) {
      var radio_choice = $(this).parents('.row.js-filter-range-time-area').find('input[type="radio"][name="order[delivery_options]"]');
      $(radio_choice).prop("checked", true);
    }
  });

  $('input.singledate').on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
  });

  $('input.js-deliver-singledate-filter').on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('YYYY/MM/DD'));

    $(this).parents('form').submit();
  });

  $('.timepicker').daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    timePickerSeconds: false,
    autoApply: true,
    autoClose: true,
    locale: {
      format: 'HH:mm'
    }
  }).on('show.daterangepicker', function (ev, picker) {
    picker.container.find(".calendar-table").hide();
    picker.container.find(".drp-buttons").hide();
    picker.container.find(".calendar-time").css("padding-right", "10px");
    picker.container.find(".hourselect").addClass("timepicker-field");
    picker.container.find(".minuteselect").addClass("timepicker-field");
  })
}

function sp_sidebar_open() {
  $("#main-body-sidebar").removeClass('d-none');
  $('body').addClass('noscroll');
  $('.overlay').removeClass('d-none');
}

function sp_sidebar_close() {
  $("#main-body-sidebar").addClass('d-none');
  $('.overlay').addClass('d-none');
  $('body').removeClass('noscroll');
}

function autosubmit_init(elements) {
  elements.unbind('change keyup').bind('change keyup', function (e2) {
    clearTimeout(window.autosubmit_timeout);
    window.autosubmit_timeout = setTimeout(() => {
      $(e2.currentTarget).submit();
    }, 400);
  })
}

$(document).on('click', '.btn-collection-registration', function (e) {
  e.preventDefault();

  let bill_id = $(this).data('item-id');
  let urlParams = new URLSearchParams(window.location.search);

  let params = {};
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  params['bill_id'] = bill_id;
  params['authenticity_token'] = $('meta[name="csrf-token"]').attr('content');

  if (bill_id != "") {
    $.ajax({
      url: '/bills/' + bill_id,
      method: 'GET',
      data: params
    }).done(function () {
    });
  }
});

$(document).on('click', '.btn-collection-result', function (e) {
  e.preventDefault();

  let bill_id = $(this).data('item-id');
  if (bill_id != "") {
    $.ajax({
      url: '/bills/' + bill_id + '/list_actual_payment',
      method: 'GET',
      data: { bill_id: bill_id, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
    }).done(function () {
    });
  }
});

$(document).on('click', '#js-update-actual-payment', function (e) {
  e.preventDefault();

  let urlParams = new URLSearchParams(window.location.search);
  let params = {};
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  let actual_payment_id = $(this).data('item-id');
  let bill_id = $(this).data('bill-id');
  params['bill_id'] = bill_id;
  params['actual_payment_id'] = actual_payment_id;
  params['amount'] = $(`#amount_${actual_payment_id}`).val();
  params['pay_date'] = $(`#pay_date_${actual_payment_id}`).val();
  params['authenticity_token'] = $('meta[name="csrf-token"]').attr('content');

  if (bill_id != "") {
    $.ajax({
      url: '/bills/' + bill_id + '/update_actual_payment',
      method: 'PUT',
      data: params
    }).done(function () {
    });
  }
});

$(document).ready(function() {
  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const setInputValue = (inputId, paramName) => {
    const paramValue = getParameterByName(paramName);
    if (paramValue) {
      $(`#${inputId}`).val(paramValue);
    }
  };

  const setCheckboxState = (checkboxId, paramName) => {
    const paramValue = getParameterByName(paramName);
    if (paramValue === '1') {
      $(`#${checkboxId}`).prop('checked', true);
    }
  };

  setInputValue('bill_end_date_gteq', 'q[bill_end_date_gteq]');
  setInputValue('bill_end_date_lteq', 'q[bill_end_date_lteq]');
  setInputValue('client_name_cont', 'q[client_name_cont]');
  setInputValue('shop_name_cont', 'q[shop_name_cont]');

  setCheckboxState('status_not_pay', 'status_not_pay');
  setCheckboxState('status_had_paied', 'status_had_paied');
  setCheckboxState('status_pay_not_enough', 'status_pay_not_enough');
  setCheckboxState('status_pay_over', 'status_pay_over');

  const paymentTypeParams = getParameterByName('q[client_payment_type_in][]');
  if (paymentTypeParams) {
    const paymentTypes = paymentTypeParams.split(',');
    paymentTypes.forEach(type => {
      if (type === '1') {
        $('#client_payment_type_credit').prop('checked', true);
      }
      if (type === '2') {
        $('#client_payment_type_real_money').prop('checked', true);
      }
    });
  }
});
